const hashtagsEN = [
    {
        "Construction and Structural Work" :           
        [
            "#HomeConstruction",
            "#AtticConversions",
            "#Carpentry",
            "#Roofing",
            "#RoofConstruction",
            "#TraditionalPlastering",
            "#MachinePlastering",
            "#Exteriors",
            "#Foundations",
            "#BuildingStructures",
            "#HallConstruction",
            "#Warehouses",
            "#ConservationWork",
            "#HistoricRenovations",
            "#HomeRenovations",
            "#NewHomeConstruction",
            "#AtticInsulation",
            "#MetalStructures",
            "#WarehouseConstruction",
            "#HistoricPreservation",
            "#ArchitecturalDesign",
            "#InteriorFinishing",
            "#WaterAndSewerSystems",
            "#ElectricalWork",
            "#SanitaryInstallations",
            "#StairConstruction",
            "#BuildingHeating",
            "#WallPainting",
            "#HeatRecovery",
            "#MasonryWork"
        ]
    },            
    {
        "Finishing Work" :       
        [   
            "#DrywallPartitions",
            "#SuspendedCeilings",
            "#Painting",
            "#Wallpapering",
            "#Tiling",
            "#InteriorFinishing",
            "#CustomCabinetry",
            "#PartitionWalls",
            "#CarpetFlooring",
            "#LaminateFlooring",
            "#VinylFlooring",
            "#WoodenFloors",
            "#InteriorRenovations",
            "#CassetteCeilings",
            "#CorniceCeilings",
            "#WallPreparation",
            "#Plastering",
            "#CustomFurniture",
            "#WallCladding",
            "#NewFlooring",
            "#CeramicAndPorcelainTiles",
            "#InteriorDecorations",
            "#InteriorLighting",
            "#InteriorDesign",
            "#HomeRenovations",
            "#OfficeFinishing",
            "#StoreFinishing",
            "#KitchenDesigns",
            "#BathroomDesigns"
        ]
    },
    {
        "Exterior and Roadwork" :
        [
            "#Paving",
            "#SidewalkInstallation",
            "#Fencing",
            "#Gates",
            "#FenceDoors",
            "#StructureDemolition",
            "#BuildingDemolition",
            "#Earthwork",
            "#RoadConstruction",
            "#ParkingConstruction",
            "#Paving",
            "#SidewalkInstallation",
            "#WoodenFences",
            "#MetalFences",
            "#GardenGates",
            "#FenceDoors",
            "#DemolitionOfWorks",
            "#StructureDemolition",
            "#Earthwork",
            "#ParkingConstruction",
            "#RoadWork",
            "#PavementInstallation",
            "#SidewalkRenovation",
            "#RoadProjectManagement"
        ]
    },                         
    {
        "Installations and Systems" :            
        [
            "#ElectricalInstallations",
            "#ComputerNetworks",
            "#AlarmSystems",
            "#PlumbingInstallations",
            "#UnderfloorHeating",
            "#DoorInstallation",
            "#WindowInstallation",
            "#AluminumJoinery",
            "#PVCJoinery",
            "#VentilationSystems",
            "#AirConditioning",
            "#SystemInstallation",
            "#LANNetworks",
            "#SecuritySystems"
        ]
    },               
    {
        "Work Related to Energy Modernization" :
                            
        [   
            "#BuildingThermalModernization",
            "#ThermalInsulation",
            "#EnergyEfficientWindowsInstallation",
            "#ExteriorRenovation",
            "#HomeElectrics",
            "#HomeAutomation",
            "#HomeSecurity",
            "#HomeMonitoring",
            "#UnderfloorHeating",
            "#DoorInstallation",
            "#WindowInstallation",
            "#PVCWindows",
            "#AluminumWindows",
            "#HomeVentilation",
            "#ApartmentAirConditioning",
            "#PlumbingInstallations",
            "#Plumber",
            "#SecuritySystems",
            "#HomeAlarm",
            "#VentilationSystems"
        ]
    },       
]

export default hashtagsEN;
