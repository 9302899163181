const hashtagsPL = [
    {
        "Prace budowlane i konstrukcyjne" :           
        [
            "#BudowaDomów",
            "#PoddaszaDomów",
            "#Ciesielstwo",
            "#Dekarstwo",
            "#BudowaDachów",
            "#TynkiTradycyjne",
            "#TynkiMaszynowe",
            "#Elewacje",
            "#Fundamenty",
            "#KonstrukcjeBudowlane",
            "#BudowaHal",
            "#Magazyny",
            "#PraceKonserwatorskie",
            "#RenowacjeZabytków",
            "#RemontDomów",
            "#BudowaNowychDomów",
            "#IzolacjaPoddaszy",
            "#KonstrukcjeMetalowe",
            "#BudowaMagazynów",
            "#PraceZabytkowe",
            "#ArchitekturaBudowlana",
            "#WykańczanieWnętrz",
            "#WodnoKanalizacyjne",
            "#PraceElektryczne",
            "#InstalacjeSanitarne",
            "#BudowaSchodów",
            "#OgrzewanieBudynków",
            "#MalowanieŚcian",
            "#Rekuperacja",
            "#PraceMurarskie"
        ]
    },            
    {
        "Prace wykończeniowe" :       
        [   
            "#ŚciankiGipsowoKartonowe",
            "#SufityPodwieszane",
            "#Malowanie",
            "#Tapetowanie",
            "#PraceGlazurnicze",
            "#WykańczanieWnętrz",
            "#ZabudowyMeblowe",
            "#SufityDziałowe",
            "#WykładzinyDywanowe",
            "#PanelePodłogowe",
            "#PodłogiPCV",
            "#PodłogiDrewniane",
            "#RemontWnętrz",
            "#SufitKasetonowy",
            "#SufitListwowy",
            "#PrzygotowanieŚcian",
            "#Szpachlowanie",
            "#MebleNaZamówienie",
            "#ZabudowaŚcian",
            "#NowaPodłoga",
            "#GlazuraITerakota",
            "#DekoracjeWnętrz",
            "#OświetlenieWnętrz",
            "#AranżacjaWnętrz",
            "#ProjektowanieWnętrz",
            "#RemontMieszkań",
            "#WykańczanieBiur",
            "#WykańczanieSklepów",
            "#ProjektyKuchni",
            "#ProjektyŁazienek"
        ]
    },
    {
        "Prace zewnętrzne i drogowe" :
        [
            "#KostkaBrukowa",
            "#UkładanieChodników",
            "#Ogrodzenia",
            "#Bramy",
            "#Furtki",
            "#DemontażKonstrukcji",
            "#RozbiórkaBudynków",
            "#PraceZiemne",
            "#BudowaDróg",
            "#BudowaParkingów",
            "#KostkaBrukowa",
            "#UkładanieChodników",
            "#OgrodzeniaDrewniane",
            "#OgrodzeniaMetalowe",
            "#BramyOgrodzeniowe",
            "#FurtkiOgrodzeniowe",
            "#DemontażBudynków",
            "#RozbiórkaKonstrukcji",
            "#PraceZiemne",
            "#BudowaParkingów",
            "#PraceDrogowe",
            "#UkładanieKostki",
            "#RemontChodników",
            "#ZarządzanieProjektamiDrogowymi"
        ]
    },                         
    {
        "Instalacje i systemy" :            
        [
            "#InstalacjeElektryczne",
            "#SieciKomputerowe",
            "#SystemyAlarmowe",
            "#InstalacjeWodnokanalizacyjne",
            "#OgrzewaniePodłogowe",
            "#MontażDrzwi",
            "#MontażOkien",
            "#StolarkaAluminiowa",
            "#StolarkaPCV",
            "#SystemyWentylacyjne",
            "#Klimatyzacja",
            "#MontażSystemów",
            "#SieciLAN",
            "#SystemyOchrony"
        ]
    },               
    {
        "Prace związane z modernizacją energetyczną" :
                            
        [   
            "#TermomodernizacjaBudynków",
            "#IzolacjaTermiczna",
            "#MontażOkienEnergooszczędnych",
            "#WykonanieElewacji",
            "#ElektrykaDomowa",
            "#AutomatyzacjaDomu",
            "#BezpieczeństwoDomu",
            "#MonitoringDomowy",
            "#OgrzewaniePodłogowe",
            "#MontażDrzwi",
            "#MontażOkien",
            "#OknaPCV",
            "#OknaAluminiowe",
            "#WentylacjaDomowa",
            "#KlimatyzacjaMieszkań",
            "#InstalacjeSanitarne",
            "#Hydraulik",
            "#SystemyBezpieczeństwa",
            "#DomowyAlarm",
            "#SystemyWentylacyjne"
        ]
    },       
]

export default hashtagsPL;
